import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageTitle from '../../components/page_title'
import PageContents from '../../components/page_contents'
import * as style from '../../styles/about.module.css'

export default function About() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='About'
        description='Vince and Mary Linden are attorneys with expertise in litigation and liquor licensing. Click here to learn about more about their talent and experience.'
      />

      <PageContents>
        <PageTitle>Who We Are</PageTitle>

        <div className={`${style.main} wide_content`}>
          <div className={style.attorneys}>
            <div className={style.individual}>
              <StaticImage
                className={style.photo}
                src='../../images/jpg/vince.jpg'
                alt='Vincent L. Linden III'
                loading='eager'
                height={350}
              />

              <span className={style.individual_name}>
                Vincent L. Linden III
              </span>

              <span className={style.individual_role}>Attorney, Partner</span>

              <Link to='/about/vincent' className={style.bio_button}>
                View Full Bio
              </Link>
            </div>

            <div className={style.individual}>
              <StaticImage
                className={style.photo}
                src='../../images/jpg/mary-normal.jpg'
                alt='Mary Kominek Linden'
                loading='eager'
                height={349}
              />

              <span className={style.individual_name}>Mary Kominek Linden</span>
              <span className={style.individual_role}>Attorney, Partner</span>

              <Link to='/about/mary' className={style.bio_button}>
                View Full Bio
              </Link>
            </div>
          </div>

          <div className={style.description}>
            Linden Law Group is dedicated to providing innovative and
            comprehensive legal services in a cost-effective way. We are
            highly-competitive attorneys who have practiced law in Colorado
            Springs since 1994. In 2005, we combined our professional strengths
            in Linden Kominek, P.C. dba as Linden Law Group. By combining our
            skillsets we have created an expansive toolbox to utilize in
            resolving the wide-range of problems which clients bring through our
            doors. We achieve outstanding results for our clients in the fields
            of civil litigation, administrative law, liquor licensing, and small
            business consulting.
            <br />
            <br />
            We take pride in our ability to tackle complex legal issues and
            resolve complicated factual situations. Success takes many different
            paths for our clients - jury trials, bench trials, appeals,
            arbitrations, mediations, creative settlements and the acceptance of
            candid well-reasoned advice. As attorneys we strive for excellence,
            but we are always client-focused – when our client prevails, we
            prevail.
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
